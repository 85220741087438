@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

.number-buttons {
  margin-top: 10px;
}

.history-page-wrapper {
  padding: 0 11px 20px 11px;
}

.container-with-dots {
  margin-top: 65px;

  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }
}

.gen-wrapper-field {
  @media screen and (min-width: $md) {
    box-shadow: 2px 2px 3px rgba(0,0,0,0.16 );
    margin-top: 10px;
  }

  input {
    @media screen and (max-width: $md) {
      box-shadow: 2px 2px 3px rgba(0,0,0,0.16 );
    }
  }
}
.responsive-generator {
  .qty-btns {
    button {
      border-radius: 5px;
      box-shadow: 0px 3px 5px rgba(0,0,0,0.16 );
      height: 51px;


      &.active,
      &:hover {
        background: $lightGreen;
        color: $black;
      }
    }
  }

  .generator-column {
      width: 100%;
    .generate-wrapper {
    
      .generator {
        width: 100%;
        @media screen and (min-width: $md) {
          width: 90%;
          height: 51px;
        }
        * {  
          border-width: 0!important;
        }
        ::placeholder {
          color: #ACACAC;
        }
    
        input {
          &:focus {
            &::placeholder {
              visibility: hidden;
    
            }
          }
        }
    
        border-radius: 5px 0 0 5px;
      }
    }
    
    
    .generate-btn {
      border-radius: 0 5px 5px 0;
      height: 51px!important;
      @media screen and (max-width: $md) {
        margin-top: 15px;
      }
    }
  
  }
  
  margin-top: 40px;
}

.examples-wrapper {
  .container-examples {
    margin-top: 20px;
  }

  .examples-title {
    margin-top: 41px;
    font-size: 30px;
    line-height: 1;
  }

  .examples {
    margin-left: -8px!important;
    margin-top: 18px;

    .example {
      
      position: relative;
      border-radius: 5px;

      img {
        width: 100%;
      }

      p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: auto;
        font-size: 20px;
        padding: 45px;
        color: #fff;
        text-align: center;
      }
    }
  }
}

.intro {
  margin-top: 48px;
}

.intro-title {
  line-height: .5;
}

.explanations-wrapper {
  margin-top: 51px;

  .accordion-wrapper {
      border-radius: 20px;
      padding: 0;
    .accordion-summary {
      box-shadow: 0px 3px 3px rgba(0,0,0,0.16 );
      padding: 20px;
      border-radius: 20px;

      .iconify {
        color: $green;
      }
    }

    .accordion-details {
      text-align: left;
      padding: 15px 55px;

    }
  }
}

.loading-screen-wrapper {
  margin: auto;
  margin-top: -60px;
  padding-top: 60px;
  width: 100%;
  height: 100vh;
  z-index: 99999999;
  top: 0;
  bottom: 0;

  .loading-icon {
    text-align: center;
    margin-top: 50px;
  }

  .loading-screen-text {
    width: 50%;
    margin: auto;
    margin-top: 70px;
    text-align: center;
  }

  .loading-screen {
    position: relative;
    margin: auto;
    margin-top: 1%;
    max-width: 482px;
    margin-bottom: -185px;

    p {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      text-align: center;
      padding: 25px;
      font-size: 20px;;
    }
  }
}

.credits-left-card {
  padding: 11px 20px 9px 21px;
  width: 75px;

  @media screen and (max-width: $md) {
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
  
  .credits-left {
    color: #1DD263;
    font-size: 30px;

    @media screen and (max-width: $md) {
      font-size: 20px;
      line-height: 2;
    }

    font-weight: bold;
    line-height: .8;
  }

}
.credit-stack {
  @media screen and (max-width: $md) {
    margin-top: 20px;
    
  }
}
.ai-credits {
  margin-top: 11px;

 
  a {
    font-size: 16px;
    font-weight: normal;
    margin-left: 17px;
  }
}



.history-btn {
  font-size: 20px;
  font-weight: 400;
  width: 75%;
  &:hover {
    background-color: $dark;
    color: $white
  }
  z-index: 1099;
}

@media screen and (max-width: $md) {
.history-btn,
.gallery-btn {
  padding: 0!important;
  min-width: 40px!important;
  width: 40px!important;
  height: 40px;
  border-radius: 100%;
  margin: auto;
}
}

.nav-btn-wrapper {
  margin-top: 20px;

  .gallery-btn {
    width: 75%;
    .iconify {
      margin-left: 5px;
    }
  }


}

.history-wrapper {
  width: 75%;
  margin-top: 0!important;

  @media screen and (max-width: $md) {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1098;
    padding: 10px;
    width: 90%;
    padding-top: 60px;
    margin-top: -45px!important;
    border-radius: 5px;
  }

  .history-title {
    font-size: 28px;
    padding: 0 10px;
  }

  .scrollbar {
    padding: 10px;
  }

  .icon-wrapper {
    background-color: #8AEAB7;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    text-align: center;
    padding-top: 1px;
    margin-left: 14px;
  }

  a {
    width: 100%;

    button {
      width: 100%;
    }
  }  
}

.package-container {
  padding-right: 20px;
  
  .packages {
    margin-top: 64px;
    margin-left: 0;

    .payment-method-title-desc {
      margin-top: 10px;
    }
  
    box-shadow: 0px 3px 3px rgba(0,0,0,0.16 );
    padding: 43px 46px 62px 47px;
  
    .package-wrapper {
      box-shadow: 0px 3px 5px rgba(0,0,0,0.25 );
      padding: 40px 46px 32px 47px;
  
      .add-text {
        font-size: 16px;
      }
  
      .MuiDivider-fullWidth {
        border-bottom-width: 2px;
        border-color: #1DD263;
        margin-top: 8px;
        width: 80%;
      }
  
      .credit-text {
        margin-top: 32px;
        font-weight: 500;
        font-size: 30px;
      }
  
      .price-text {
        margin-top: 10px;
        color: #1DD263;
        font-size: 27px;
        font-weight: bold;
      }
  
      .buy {
        width: 127.4px;
        margin: auto;
        margin-top: 43px;
        display: block;
        line-height: .5;
        width: 100%;
        font-size: 16px;
        height: 35px;
      }
    }
  
    .MuiGrid-item {
      position: relative;
  
      .most-popular-header {
        background-color: #F0B25C;
        font-family: 'League Spartan', sans-serif;
        text-align: center;
        width: 80%;
        border-radius: 5px;
        z-index: 999;
        position: absolute;
        left: 17%;
        top: 22px;
        box-shadow: 0px 3px 3px rgba(0,0,0,0.16 );

        .text {
          font-size: 17px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
}

.history-card {
  padding: 0;
  text-align: left;
  border-radius: 0;
  box-shadow: none!important;

  img {
    object-fit: cover;
  }

  .history-images {
    margin-top: 16px;
  }

  .inactive {
    color: #ACACAC
  }
}

.generated-image {
  text-align: center;

  button {
    border-radius: 5px;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.16 );
    background-color: #fff;
    color: #000;
    box-shadow: none;

    &.btn-variations {
      width: 150px;
    }

    svg {
      margin-right: 10px;
    }

    &:hover {
      background-color: #efefef;
    }
  }


}

.history-show {
  margin-top: 27px;
  font-size: 17px;
  font-weight: normal;
}

.icon {
  @media screen and (min-width: $md) {
    margin-left: 23px;
  }
}


.generator {
  .MuiInputBase-multiline {
    background-color: $white;
  }
}

.dark-mode {
  .generator {
    .MuiInputBase-multiline {
      background-color: $dark;
    }
  }
}

@media screen and (max-width: 900px) {
  .loading-screen-wrapper {
    .loading-screen-text {
      padding: 0 20px;
    }
  }
  
  .heading-btn-container {
    text-align: center;
  }

  .examples-wrapper {
    .examples {
      .example {
        img {
          height: 156px;
        }

        p {
          padding: 21px 10px 23px 11px;
          font-size: 17px;
        }
      }
    }
  }

  .generate-wrapper {

    .generator {
      width: 100%;
      margin-top: 10px;
    }

    .generate-btn {
      border-radius: 5px;
      width: 100%;
    }
  }

  .ai-credits {
    margin-top: 0;
  }

  .intro-title {
    line-height: 1;
  }


  .package-container {
    padding-right: 0;
    
    
    .packages {
      margin-top: 6px;
      padding: 31px 25px 41px 25px;
    
      .package-wrapper {
        padding: 40px 46px 32px 38px;
      }

      .MuiGrid-item {
        .most-popular-header {
          left: 10%;
        }
      }
    }
  }
}

.tips-wrapper {
  margin-top: 15px;

  h1 {
    font-size: 25px;
    font-weight: normal;
  }

  h2 {
    color: $green!important;
    font-size: 20px;
    font-weight: normal;
  }

  p {
    font-size: 17px;
  }
}

.show-tips-btn {
  margin-top: 54px;
  padding: 0;
  .iconify {
    margin-left: 10px;
  }
}

.shared-image-wrapper {
  display: block;
  margin: auto;
  margin-top: 50px;
  text-align: center;

  img {margin: auto;}
}

.load-more-images {
  box-shadow: 0px 3px 3px rgba(0,0,0,0.16);
  width: 33%;
  margin: auto;
  margin-top: 35px;
  display: block;
}

.hsitory-button-wrapper {
  margin-top: 20px;

  @media screen and (max-width: $md) {
    text-align: center;
  }
}

.gallery-link {
  @media screen and (max-width: $md) {
    text-align: center;
  }
}