.tracks-wrapper-title {
  padding-bottom: 20px;
}

.responsive-track {
  padding: 5px;

  .track-card {
    box-shadow: 0px 3px 3px rgba(0,0,0,0.16 );
    border-radius: 20px;
    padding: 10px;
    .track-image {
      img {
        border-radius: 20px;
      }
    }
    .track-info {
      padding: 20px 0;
      text-align: left;
      color: #000;
      .track-name {
        font-weight: bold;
      }
    }
  }
}