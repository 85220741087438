$green: #1DD263;
$positive: #7BD89F;
$negative: #E67B7B;
$neutral: #6d6d6d;
$white: #fff;
$black: #000;
$dark: #242424;
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$lightGreen: #99f4bc;
$faded: #CCCCCC;
:root {
  --paper-background-color: $white;
  --paper-text-color: $black;
}

.dark-mode {
  --paper-background-color: $dark;
}
