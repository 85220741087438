@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');


.platforms-grid-overlay-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: .5;
  top: 0;
  bottom: 0;
  z-index: 999999;
  left: 0;
}

.account-social-register {
  padding: 20px;
}

.account-social-register {
  box-shadow: 0px 3px 5px rgba(0,0,0,0.25 );
  padding-top: 1px;
  margin-top: 20px;
}


.register-social-desc,
.social-accordion-stack {
  margin-top: 50px;
}

.social-accordion-stack {
  margin-top: 50px
}
.social-register-wrapper {
  margin-bottom: 20px;
  max-width: 800px;
}
@media screen and (max-width: $md) {
  .no-acc-text {
    display: none;
  }
}

.social-register-accordion-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0;


  button {
    border-radius: 5px;

    @media screen and (max-width: $md) {
      margin-left: auto!important;
    }
  }
}

.register-socials-name {
  font-weight: 400;
  margin: 0 20px;
  font-size: 17px;
  width: 70px;
}

.add-social-id-form-wrapper {
  @media screen and (min-width: $md) {
    width: 82%;
  }
  margin-left: auto;
  margin-right: 0!important;
}

.social-insufficient-link {
  font-weight: 400!important;
}

.add-socials-button {
  background-color: $green;
  width: 40px;
  height: 40px!important;
  padding: 0;
  border: none!important;


  .iconify {
    color: $white;
  }

}

.add-socials-input {
  @media screen and (min-width: $md) {
    margin-top: -2px!important;
  }
  input {
    height: 25px;
  }
}

.social-media-container {
  margin-top: 24px;
  overflow: hidden;
  padding-bottom: 183px;
  @media screen and (min-width: $lg) {
    width: calc(10/12 * 100%);
  }

  .graph {

    &.growth {
      height: 306px;
    }
  }

  .table-data {
    .MuiPaper-root:not(.MuiAlert-root) {
      box-shadow: 0px 3px 5px rgba(0,0,0,0.16 );

      td {
        padding: 32px 20px 22px 20px;
      }
    }
  }



  .stats-wrapper {
    position: relative;
    // .apexcharts-legend-series[rel="2"] {
    //   display: none!important;
    // }

    margin-top: 24px;

    .hide-stack {
      .MuiStack-root {
        display: none;
      }
    }

    .switch-data-type {
      margin-left: 42px;
      margin-top: 10px;
      color: #fff;
      .MuiInputBase-input {
        padding: 10px;
        padding-right: 30px;
        color: #fff;
      }
      .css-sdgeaz-MuiSvgIcon-root-MuiSelect-icon{
        color: #fff;
      }
      .css-4sa4h-MuiSvgIcon-root-MuiSelect-icon{
        color: #fff;
      }
      .MuiOutlinedInput-notchedOutline{
        border-color: rgba(255, 255, 255, 0.552);
      }
    }
    .MuiStack-root, .select-type-buttons {
      margin: auto;
    }

    .overview-wrapper {

      text {
        fill: #fff;
      }
      @media screen and (min-width: $md) {
          padding: 24px 30px 15px 5px;
      }
      .select-type-buttons {
        width: 92%;
        margin-left: auto;
        margin-right: 10px;
        z-index: 998;
        .btn {
          background-color: $green;
          border: 1px solid $white;
          font-size: 17px;
          color: $white;
          font-weight: normal;
          padding: 10px 20px;
          max-height: 40px;
          position: absolute;
          top: 15px;
          z-index: 999;
          &.active {
            background-color: $white;
            color: $black;
          }

          &.inactive {
            bottom: 0;
            top: 50px;
          }
        }

        .date {
          width: 100%;
          margin-top: 10px;

          @media screen and (min-width: $lg) {
            margin-top: 0px;
          }

          .MuiFormControl-root {
            margin-top: 0;
          }
          input {
            color: #fff;
            height: 10px;
            font-size: 15px;
          }

          label {
            border: 1px solid #fff;
            padding: 3px 4px 2px 12px;
            border-radius: 5px;
            border-color: #fff!important;
            color: #fff;
            font-size: 15px;
            margin-top: -3px;
          }

          .MuiSvgIcon-root {
            color: #fff;
          }
        }
      }
    }

    .other-stats-card {
        padding: 0px;
        position: relative;

        .content-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;

          .content-container {
            padding: 0;
            margin: auto;
          }
        }

      text-align: center;

      .trend {
        .positive {
          color: $positive;
        }

        .negative {
          p {
            color: $negative;
          }
        }

        .neutral {
          color: $neutral;
        }

        .info-stack {
          @media screen and (min-width: $lg) {
            height: 49px;
          }

          height: 34px;
          padding-top: 0;
          width: 100%;
        }
      }

      .percentage {
        font-size: 15px;
        width: 100%;

        .percentage-number {
          top: -10px;


          position: relative;
        }
      }
    }

    .small-text {
      font-size: 11px;
    }

    .number {
      font-size: 1em;
      @media screen and (min-width: $md) {
        font-size: 1.5em;
      }
      font-weight: bold;
      color: $green;

      @media screen and (min-width: $lg) {
        font-size: 2.5em;
      }
    }


    @media screen and (max-width: $md) {
      .content {
        padding: 0px;
        padding-bottom: 10px;
      }
    }

    .content {
      font-size: 1em;
      @media screen and (min-width: $lg) {
        font-size: 1.5em;
      }
      padding: 0;
    }

    .MuiPaper-root {
      position: relative;

      &:before {
        content: '';
        display: block;
        height: 78%;
        width: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $green;
        background-color: var(--custom-border-color);
        border-bottom-left-radius: 10px;
        z-index: 1;
      }

      &:after {
        content: '';
        display: block;
        height: 4px;
        width: 78%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $green;
        background-color: var(--custom-border-color);
        border-bottom-left-radius: 10px;
      }
    }

    @media screen and (max-width: $sm) {

      .other-stats {

        .content-container {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          border-bottom: 1px solid #403e3e;
          margin: unset !important;
          padding: 0 30px !important;
          font-size: 20px;
        }

        .MuiPaper-root {

          &:after,
          &:before {
            display: none;
          }
        }

        .MuiGrid-item {

          .MuiPaper-root {
            border-radius: 0;
          }

          &:nth-child(2) {

            .MuiPaper-root {
              border-radius: 8px 8px 0 0;
            }
          }

          &:last-child {

            .MuiPaper-root {
              border-radius: 0 0 8px 8px;
            }
          }
        }
      }
    }
  }

  .choose-text {
    margin-top: 30px;
  }

  @media screen and (max-width: $md) {
    .platforms-grid {
      position: absolute;
      z-index: 9999999;
      width: 100%;
      margin-top: 0px !important;
      padding-bottom: 50px;
      left: 0;
      margin-left: -11px;
    }
  }


  .active-platform {
    &.overlayed {
      z-index: 99999999;
    }
    position: relative;
    .social-card {
      border-bottom-left-radius: 0;
      height: 168.5px;
      &.small-radius {
        height: 115px;

        &.active {
          margin-bottom: 0;
        }
      }

      &:before {
        content: '';
        display: block;
        height: 78%;
        width: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $green;
        background-color: var(--custom-border-color);
        border-bottom-left-radius: 10px;
      }

      &:after {
        content: '';
        display: block;
        height: 4px;
        width: 78%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $green;
        background-color: var(--custom-border-color);
        border-bottom-left-radius: 10px;
      }
    }
  }


  .social-card {
    padding-bottom: 0;
    box-shadow: 0px 3px 5px rgba(0,0,0,0.16 );
    border-radius: 20px;

    &.small-radius {
      border-radius: 8px;
      padding-bottom: 15px;
      margin-bottom: -35px;
    }

    .diff-wrapper {
      .green {
        color: $green;
      }

      .red {
          color: $negative;
      }
    }

    .media {
      img {
        height: 32px;
        max-width: 100%;
        object-fit: contain;

        @media screen and (min-width: $md) {
          height: 46px;
        }

        &.big {
          height: 50px;
        }
      }
    }

    .content {
      .amount {
        font-size: 1.5em;
        text-align: left;
        color: #1dd263;

        @media screen and (min-width: $md) {
          font-size: 2em;
        }
      }

      .datatype {
        font-size: 15px;
        //text-align: start !important;
        text-align: left;
        padding: 0;
        margin-top: 29px;
        @media screen and (max-width: $md) {
            text-align: start !important;
        }
      }

      padding: 0;
    }
    .showWithDataType {
    }
    .dataTypeInfo {
      margin-right: 4em;
      //@media screen and (max-width: $md) {
      //  margin-right: 3em;
      //  margin-top: 2.2em;
      //}
      @media screen and (min-width: $md) {
        margin-right: inherit;
        margin-top: inherit;
      }
    }
    .menuButtonDataType {
      margin-top: -2em;
    }
    .socialDropDownDataType {
      margin-top: -1.7em;
      @media screen and (min-width: $md) {
        display: none;
      }
    }
  }
}
.platforms-grid::before {
  content: "";
  display: block;
  height: 90%;
  width: 4px;
  position: absolute;
  bottom: 13px;
  left: 21px;
  background-color: $green;
  border-bottom-left-radius: 10px;
  @media screen and (min-width: $md) {
    display: none;
  }
}
.platforms-grid::after {
  content: "";
  display: block;
  height: 4px;
  width: 78%;
  position: absolute;
  bottom: 13px;
  left: 23px;
  background-color: $green;
  border-bottom-left-radius: 10px;
  @media screen and (min-width: $md) {
    display: none;
  }
}
.active-platforms {
  z-index: 999999;
  position: relative;
}
.active-platform .showWithDataType .datatype {
  @media screen and (min-width: $md) {
    text-align: right !important;
  }
}
.socialIconMenuinsta {
  margin-left: 12.8px !important;
}
.socialIconMenutt {
  margin-left: 2px !important;
}
.socialIconMenufb {
  margin-left: 10px !important;
}
.socialIconMenuyt {
  margin-left: 8px !important;
}
.socialIconMenutw {
  margin-left: 5.5px !important;
}
.topMargin {
  margin-top: -35px;
  @media screen and (min-width: $md) {
    margin-top: inherit;
  }
}

.custom-border {
  position: relative;
  border-radius: 10px;
  border-bottom-left-radius: 0;

  &:before {
    content: '';
    display: block;
    height: 78%;
    width: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $green;
    background-color: var(--custom-border-color);
    border-bottom-left-radius: 10px;
  }

  &:after {
    content: '';
    display: block;
    height: 4px;
    width: 78%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $green;
    background-color: var(--custom-border-color);
    border-bottom-left-radius: 10px;
  }
}
