@import 'variables';

.MuiDrawer-root {
  z-index: 12001 !important;
}

.p-0 {
  padding: 0;
}

.overflow-hidden {
  overflow: hidden;
}

li.MuiMenuItem-root {
  background-color: transparent !important;

  .MuiTouchRipple-root {
    display: none;
  }
}

.mobile-menu {
  .locked-menu-item {
    display: inline;
    color: $white;
    padding: 6px 16px;

    * {
      display: inline;
      color: $faded !important;
    }

    p {
      position: relative;
      top: -1px;
      left: 5px;
      font-size: 30px;
    }

    .custom-tooltip {
      display: none;
    }
  }

  .sub-menu {
    .locked-menu-item {
      padding: 0;

      p {
        font-size: 17px;
        top: -5px;
      }
    }
  }
}

.nav-bar {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.04);
}

.spotify-id-filled {
  input {
    -webkit-text-fill-color: #acacac !important;
    color: #acacac !important;
  }
}

.checkout-modal {
  .checkout-modal-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.main-view {
  min-height: 100vh;
}

.text-underline {
  text-decoration: underline;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.pl-1 {
  padding-left: 10px;
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-2 {
  padding-right: 20px !important;
}

.pl-2 {
  padding-left: 20px;
}

.mt-1 {
  margin-top: 10px;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.text-black {
  color: $black !important;
}

.bg-white {
  background-color: #fff;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.dark-mode {
  background-color: #000;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  svg,
  label {
    color: $white;
  }

  .bg-white {
    background-color: $dark;
  }
}

.negative {
  color: $negative;
}

.positive {
  color: $positive;
}

.paper-bg {
  background-color: $white;
  color: $black;
}

.dark-mode {
  .paper-bg {
    background-color: $dark;
    color: $white;
  }
}

.contrast-neutral-bg {
  color: $black;
}

.dark-mode {
  .contrast-neutral-bg {
    color: $white;
  }
}

.contrast-neutral-bg-r {
  color: $white;
}

.dark-mode {
  .contrast-neutral-bg-r {
    color: $black;
  }
}

.c-pointer {
  cursor: pointer;
}

.head-menu {
  .menu-text {
    margin: 16px 0;
    display: block;
    font-size: 20px;
    font-weight: 400;
  }

  .MuiStack-root {
    cursor: pointer;
  }

  .locked-menu-item {
    display: inline-flex;
    position: relative;

    .custom-tooltip {
      background-color: #333333;
      position: absolute;
      color: $white;
      border-radius: 4px;
      width: 277px;
      text-align: left;
      top: 35px;
      transition: opacity 0.5s;
      opacity: 0;
      height: 0;
      width: 0;
      padding: 0;

      p {
        display: none;
      }
    }

    @media screen and (min-width: $md) {
      &:hover {
        .custom-tooltip {
          padding: 22px 59px 26px 31px;
          opacity: 1;
          height: auto;
          width: 280px;
          z-index: 99999;

          p {
            display: block;
          }
        }
      }
    }

    .locked {
      color: $faded;

      .iconify {
        color: $faded;
        margin-right: 10px;
      }
    }
  }

  position: relative;

  .sub-menu {
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s;
    margin-top: -10px;
    bottom: 0;
    top: 20px;
    border-radius: 10px;
    left: -10px;
    height: 0;
    padding: 0;
    width: 0;
    height: fit-content;
    width: 200px;
    padding: 20px;

    a {
      text-align: left;
    }

    p {
      display: none;
    }
  }

  &:hover {
    .sub-menu {
      opacity: 1;
      display: block;
      margin-top: 0;
      z-index: 999;
    }

    p {
      display: block;
    }
  }

  .sub-menu:hover {
    opacity: 1;
    display: block;
    margin-top: 0;
  }
}

.bg-neutral {
  background-color: $white;
}

.bg-neutral-hover:hover {
  background-color: $white;
}

.dark-mode {
  .bg-neutral {
    background-color: $dark;
  }

  .bg-neutral-hover:hover {
    background-color: $dark;
  }
}

.border-neutral {
  border-color: $white;
}

.dark-mode {
  .border-neutral {
    border-color: $dark;
  }
}

.spotify-id-filled {
  p {
    margin-left: unset;
  }
}

.responsive-menu-wrapper {
  .sub-menu {
    background-color: #00000015;
    margin-top: 10px;
    padding: 10px 15px;
    border-radius: 10px;
    margin-left: -30px;
  }
}

.responsive-menu-wrapper {
  .sub-menu {
    .contrast-neutral-bg {
      color: $white;
    }
  }
}

.dark-mode.mobile-menu {
  p,
  .sub-menu p,
  svg {
    color: $dark;
  }
}

.text-bold {
  font-weight: bold;
}

@media screen and (min-width: $xs) {
  .container {
    margin-top: 50px;
  }
}

@media screen and (min-width: $md) {
  .container {
    margin-top: 157px;
  }
}

.red-text {
  color: #c40505;
}

.css-1uhmgdu-MuiGrid-root,
.css-hz5cv-MuiGrid-root {
}

.green-text {
  color: #1dd263;
}

// changing the side menu z-index for less 1
.css-1mjkunu-MuiGrid-root,
.css-1lq0e4i-MuiGrid-root {
  z-index: 990;
}

.css-1uhmgdu-MuiGrid-root,
.css-hz5cv-MuiGrid-root {
  margin-top: 2rem;
}

@media screen and (max-width: $sm) and (min-width: $xs) {
  .app-logo {
    width: 110px;
  }

  .footer-contact {
    max-width: unset;
    margin-bottom: 15px;
  }

  .MuiBox-root .css-lwqgnd {
    height: 76.5px;
  }

  .css-1uhmgdu-MuiGrid-root,
  .css-hz5cv-MuiGrid-root {
    margin-top: unset;
  }
}

// style={{ position: "absolute", bottom: "100px", color: "white" }}
.native-prompt {
  position: 'fixed' !important;
  color: white;
  background-color: #000;
  height: 100px;
  width: 200px;
}
.subscription p {
  color: #000 !important;
}
