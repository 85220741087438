

.survey-btn-wrapper {
  .survey-btn {
    all: unset;
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #1dd263;
    color: #fff;
    font-size: 18px;
    border-radius: 4px;
    padding: 0 30px;
    font-weight: 400;
    height: 45px;
    cursor: pointer;
    line-height: 45px;
    text-align: center;
    margin: 0;
    text-decoration: none;
  }
  text-align: right;
}

.contibution-title {
  font-size: 20px;
}

.trees-share-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border: 2px solid #000;
  padding: 10px;
  text-align: center;
  border-radius: 10px;

  #modal-modal-title {
    font-weight: bold;
    font-size: 24px;
  }

  .share-icons {
    margin-top: 15px;
  }
}

.tree-intro {
  margin-top: 44px;

  .intro-title {
    color: #1DD263;
    font-size: 25px;
  }

  .intro-text {
    font-size: 17px;
  }
}

.trees-planted {
  margin-top: 16px;

  .planted-title-indi {
    font-size: 22px;
    margin-top: 22px;
  }

  .planted-indi {
    font-size: 17px;
  }

  .tree-collective-card {
    text-align: center;
    padding: 25px;
    height: 228px;

    .planted-title {
      font-size: 30px;
    }

    .planted {
      background-color: #99f4bc;
      width: 60%;
      font-size: 20px;
      padding: 5px;
      padding-top: 10px;
      margin: auto;
      margin-top: 23.4px;
      border-radius: 20px;
      line-height: 0.7;
      color: #000;
    }

    .trees-img {
      margin: auto;
      margin-top: 25px;
    }

    .trees-img-indi {
      margin: auto;
      height: 107px;
    }

    .share-btn {
      font-size: 17px;
      padding: 10px;
    }


    box-shadow: 0px 3px 3px rgba(0,0,0,0.16 );
  }
}