@import 'variables';

.showmore-title {
  font-size: 19px;
}

.orders-subscribe-cta {
  padding: 30px 50px;
  background-image: url('../../public/assets/bgs/cta-ygp.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  border-radius: 52px;

  p {
    font-size: 21px;

    &.small {
      font-size: 15px;
    }
  }

  .point {
    .green-dot {
      width: 15px;
      height: 15px;
      background-color: $green;
      border-radius: 100%;
      display: inline-block;
    }

    .text {
      display: inline-block;
      font-size: 18px;
    }

    &:nth-child(1) {
      .text {
        width: 50%;
      }
    }

    &:nth-child(2) {
      .text {
        width: 60%;
      }
    }

    &:nth-child(3) {
      .text {
        width: 50%;
      }
    }

    &:nth-child(4) {
      .text {
        width: 75%;
      }
    }
  }
}

.campaign-manager-wrapper {
  .cm-location {
    .iconify {
      margin-bottom: -3px;
    }
  }

  .runner-availability {
    margin-top: 11px;

    .availability-days {
      .availability-day {
        display: inline;
        margin: 5px;
        padding: 4px 7px;
        border-radius: 100%;
        background-color: #fc9090;

        &.available {
          background-color: #99f4bc;
        }
      }
    }
  }
}

.view-amount {
  font-size: 48px;
}

.url-table-layout {
  box-shadow: none;
  filter: none;
  border-radius: 5px;
  padding: 0;
  margin-top: 10px;

  th,
  td {
    padding: 10px !important;
    border: none;
    background: #fff;
  }
}

.campaign-extra-info-wrapper {
}

.noti {
  padding: 4px;
}

.green-box {
  background-color: #99f4bc;
}

.white-box {
  background-color: #fff;
}

.white-box,
.green-box {
  border-radius: 20px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  padding: 15px;
  @media screen and (min-width: $md) {
    padding: 30px;
  }
  height: 83%;
}
.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-body > .delete-modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.delete-modal-btn > .cancel-button {
  background-color: rgb(204, 3, 3);
  color: white;
  padding: 10px 20px;
  border: 1px solid red;
  font-size: 17px;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    padding: 8px 15px;
    border: 1px solid red;
    font-size: 15px;
  }
}
.delete-modal-btn > .cancel-button:hover {
  background-color: rgb(240, 3, 3);
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.delete-modal-btn > .delete-button {
  background-color: rgb(2, 177, 2);
  color: white;
  padding: 10px 20px;
  border: 1px solid rgb(2, 177, 2);
  font-size: 17px;
  border-radius: 5px;
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.delete-modal-btn > .delete-button:hover {
  background-color: rgb(4, 165, 4);
  color: white;
  padding: 10px 20px;
  border: 1px solid green;
  font-size: 17px;
  border-radius: 5px;
  cursor: pointer;
}

.delete-modal-btn > .delete-button-mobile {
  background-color: rgb(2, 177, 2);
  color: white;
  padding: 8px 15px;
  border: 1px solid rgb(2, 177, 2);
  font-size: 15px;
  border-radius: 5px;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}
.delete-modal-btn > .delete-button-mobile:hover {
  background-color: rgb(4, 165, 4);
  color: white;
  padding: 8px 15px;
  border: 1px solid rgb(2, 177, 2);
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
}
.cta-wrapper {
  .cta-title {
    font-size: 22px;
    line-height: 1.2em;
    @media screen and (min-width: $md) {
      font-size: 32px;
    }
  }
}

.noti-bell {
  padding: 8px 5px 1px 5px !important;
  font-size: 16px !important;
}

.dashboard-table-cell {
  padding: 16px 10px !important;
  padding-bottom: 30px !important;

  @media screen and (min-width: $md) {
    padding: 16px 7% !important;
    padding-bottom: 50px !important;
  }
}

.date-col {
  text-wrap: nowrap;
}

.secondary-email-display {
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .modal.show {
    display: block;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    background-color: #212121;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    .title {
      .main {
        color: #fff;
        font-weight: 400;
        > * {
          font-weight: 400;
        }
      }
      color: #3e3d3d;
      font-size: medium;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .body {
      .email-form {
        display: flex;
        justify-content: left;
        margin: 15px auto 15px auto;
        .email-label {
          margin: auto 10px auto auto;
        }
        .email-input {
          display: block;
          width: 100%;
          padding: 1rem 0.75rem;
          font-size: 1rem;
          line-height: 1.5;
          color: #fff;
          background-color: #535353;
          background-clip: padding-box;
          border: 1px solid #535353;
          border-radius: 0.25rem;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          &::placeholder {
            color: #fff; /* Change the color of the placeholder text */
          }
        }
        .email-input:focus {
          border-color: #80bdff;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }
    }
    .errors {
      //background-color: #ff9999;
      color: red;
      //padding: 10px 20px;
      //width: 100%;
      border-radius: 5px;
      margin: auto auto 15px;
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 2rem;
      .submit {
        display: inline-block;
        font-weight: 400;
        color: #fff;
        background-color: #28a745;
        border: 1px solid #28a745;
        padding: 0.8rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        text-align: center;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;
        width: 100%;
      }
      .submit-btn {
        width: 100%;
        background-color: #239023;
        padding: 0.8rem 0.75rem;
        //color: #fff;
      }
      .submit:hover {
        color: #fff;
        background-color: #218838;
        border-color: #1e7e34;
      }
      .submit:focus {
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
      }
    }
  }
  .close {
    color: #1a1a1a;
    float: right;
    border: none;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    width: 40px;
    height: 35px;
    border-radius: 4px;
  }
  .alert {
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .notification {
    position: fixed; /* Fixed position */
    top: 15px;
    right: 15px;
    padding: 10px;
    min-width: 300px;
    border: 1px solid transparent;
    border-radius: 4px;
    z-index: 9999;
  }
  .alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
  }
  .alert-warning {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
  }
  .alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    .emails-view {
      .email {
        margin-right: 0.3rem;
        margin-bottom: 0.5rem;
        &.selected {
          //background-color: lightblue;
          border: 3px solid lightgreen;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
        }
        &.disabled {
          background-color: gray;
          cursor: not-allowed;
        }
        .name-icon {
          display: inline-block;
          width: 24px;
          height: 24px;
          border: 2px solid #a7dbfb;
          font-size: 16px;
          text-align: center;
          margin-right: 5px;
          border-radius: 50%;
          transition: background-color 0.3s;
          background-color: #a7dbfb;
          box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
        }
        .secondary-email-div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          min-width: 210px;
        }
        .remove-email {
          padding: 3px 4px;
          border-radius: 50%;
          background-color: red;
          color: white;
          border: 0;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .remove-email-icon {
          height: 15px;
          width: 15px;
        }

        display: inline-block;
        padding: 5px 10px;
        border-radius: 20px;
        background-color: white;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
    }
    .import-order {
      position: relative;
      button {
        border-radius: 20px;
        background-color: #fff;
        border: none;
        font-weight: 400;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        padding: 6px 10px;
        min-width: 12rem;
        margin-bottom: 0.5rem;
      }
      .plus-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        background-color: transparent;
        border: 2px solid #888;
        color: #888;
        font-size: 16px;
        text-align: center;
        line-height: 23px;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 5px;
        transition: background-color 0.3s;
      }
      .plus-icon:hover {
        background-color: #f0f0f0;
      }
      .import-order-btn {
        color: #6d6d6d;
        font-weight: 300;
        font-size: 1.2rem;
        cursor: pointer;
      }
      .remove-email-trash {
        position: absolute;
        right: 0;
        padding: 10px !important;
        top: 0px;
        background: #ff0000;
        color: white;
        display: none;

        border-radius: 8px;
        border: 1px solid #ff0000;
        @media screen and (max-width: 768px) {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 30px !important;
        }
      }
      .remove-email-trash:active {
        background: #ff0000;
        background-color: white;
        color: #ff0000;
      }
    }
  }
}

@media screen and (max-width: $md) {
  .MuiTableCell-root {
    padding: 10px 13px;
  }
  .secondary-email-display {
    .row {
      flex-wrap: wrap;
      flex-flow: column-reverse;
    }
  }
}
