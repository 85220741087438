@import 'variables';

.back-to-stats {
  button {
    border-radius: 0 !important;
    position: fixed;
    z-index: 9999;
  }
}

.contained-reloader {
  .reload {
    * {
      color: $white;
    }
  }

}

.add-spotify-id-section {
  margin-top: 61px;
  margin-bottom: 150px;

  p {
    word-wrap: break-word;
  }
}

.search-artist-btn {
  width: fit-content;
  padding: 8px 22px;
}

.search-artist-wrapper {

  .artist-search-list {
    margin-top: 3px;

    .selected-artist-search-result {
      border: 2px solid $green;
      border-radius: 5px;
    }
  }

  .search-artist {
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
    border: none;
    width: 100%;

    input,
    fieldset {
      border: none;
    }

    &::placeholder {
      color: #BCBCBC;
    }

    border-radius: 5px;
  }
}

.catalog-search {
  max-width: 600px;
  width: 100%;
}

.add-id-tips-title {
  font-size: 30px;
}

.badge {
  background-color: #1DD263;
  padding: 10px 15px;
  font-size: 22px;
  border-radius: 20px;
  width: fit-content;
  color: white;

  @media screen and (max-width: $md) {
    margin-top: 25px;
  }
}

.dark-mode .badge {
  background-color: #1DD263;
  padding: 10px 15px;
  font-size: 22px;
  border-radius: 20px;
  width: fit-content;
  color: black;

  @media screen and (max-width: $md) {
    margin-top: 25px;
  }
}

.add-spotify-id {
  height: 90vh;
  padding: 10px;

  @media screen and (max-width: $md) {
    .MuiIconButton-root {
      margin-left: auto;
    }
  }
}

.audience-overview {
  min-height: 500px;
  text-align: center;

  .no-data {
    width: 100%;
    margin-top: 250px;
  }
}

.your-audience,
.no-tracks-container,
.no-history-data {
  min-height: 250px;

  @media screen and (min-width: $md) {
    border-radius: 20px;
    min-height: 530px;
  }

  .no-data {
    width: 100%;
    margin-top: 100px;

    @media screen and (min-width: $md) {
      margin-top: 225px;
    }
  }
}

.current-stat-items {
  .current-stat-item {
    position: relative;

    .MuiPaper-root {
      border-radius: 20px;
      border-bottom-left-radius: 0;
    }
  }
}

.top-track-container {
  @media screen and (max-width: $md) {
    margin-top: 8px;
  }

  .track-card {
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    padding: 14px 14px 22px 14px;

    &.active {
      border: 1px solid;
    }

    .track-image {
      img {
        border-radius: 20px;
      }
    }

    .track-info {
      padding: 0;
      text-align: left;
      color: $black;

      .track-name {
        font-weight: bold;
        margin-top: 12px;
        font-size: 17px;
      }

      .track-stats {
        margin-top: 10px;
      }
    }

  }
}