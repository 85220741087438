@import 'variables';

.css-1dvf6ky {
  overflow-x: hidden;
}

#edge-translate-panel-body, .MuiTypography-body1.coupon-text {
  color: #000!important;
}


.coupon-generator-wrapper {
  .use-coupon-btn {
    margin-top: 67px;

    button {
      max-width: 100%;
      width: 344.6px;
      font-size: 21px;
      margin-bottom: 200px;
    }
  }

  .coupons-box {
    .coupon-generator-desc {
      .coupon-generator-intro-title {
        margin-top: 25px;
        margin-bottom: 10px;
      }
    }


    h4 {
      color: $green;
      font-size: 20px;
    }

    .coupon-generator-desc-2 {
      margin-top: 54px;
    }

    .coupon-generator-desc-3 {
      margin-top: 30px;
    }
  }

  .coupon-box-img {
    @media screen and (max-width: $sm) {
      margin-top: 72px;
    }

  }

  .coupon-box-img,
  .claim-coupon-btn {
    @media screen and (min-width: $md) {
      width: 48%;
      margin-left: auto;
      margin-right: 15%;
      text-align: center;
    }
    position: relative;


    button {
      width: 313.5px;
      margin: auto;
      margin-top: 24px;
    }

    .transformable {
      transition: all .5s;
    }

    .cap {
      &.transform {
        transform: translate(60%, 66px) rotate(75deg);
      }

      margin-bottom: -20px;
      transform: scale(1.05);
      position: relative;
    }


    .coupon-box-main {
      z-index: 998;
      position: relative;
    }


    .coupon {
      margin-top: 30px;
      max-width: 50%;
      margin: auto;
      position: absolute;
      z-index: 990;
      top: 77px;
      right: 0;
      left: 0;
      opacity: 0;

      &.transform {
        transform: translate(0, -332px);
        opacity: 1;
        z-index: 999;

        &.transformc {
          transform: translate(0, -130px);
          transition: all .8s;
        }
      }

      .coupon-text {
        color: #000!important;
        text-align: center;
        position: absolute;
        right: 0;
        left: 0;
        @media screen and (min-width: $xs) {
          transform: translate(0px, -202%);
          font-size: 1.2rem;
        }
        @media screen and (min-width: $md) {
          transform: translate(0px, -182%);
          font-size: 0.9rem;
        }
        @media screen and (min-width: $lg) {
          transform: translate(0px, -207%);
          font-size: 1.1rem;
        }

        font-weight: 600;
      }
    }
  }
}
@media screen and (max-width: $sm) and (min-width: $xs) {
  .coupon-generator-wrapper {
    .use-coupon-btn {
      button {
        width: 100%;
      }
    }
  }
}